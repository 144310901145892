
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  watch,
  getCurrentInstance,
  PropType,
  ComputedRef,
} from "vue";
import { useI18n } from "vue-i18n";
import { FbmOrder } from "@/core/types/FbmOrderTypes";

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

export default defineComponent({
  name: "fbmorder-shipped-listtable",
  emit: ["current-change", "sort", "items-per-page-change"],
  props: {
    tableData: {
      type: Object as PropType<Array<FbmOrder>>,
      required: true,
    },
    emptyTableText: { type: String, default: "No data found" },
    loading: { type: Boolean, default: false },
    currentPage: { type: Number, default: 1 },
    enableItemsPerPageDropdown: { type: Boolean, default: true },
    total: { type: Number, default: 0 },
    rowsPerPage: { type: Number, default: 25 },
    order: { type: String, default: "asc" },
    sortLabel: { type: String, default: "" },
  },
  components: {},
  setup(props, { emit }) {
    const data = ref(props.tableData);
    const currentSort = ref<string>("");
    const order = ref(props.order);
    const label = ref(props.sortLabel);
    const pagination = ref<IPagination>({
      page: 1,
      total: props.total,
      rowsPerPage: props.rowsPerPage,
    });
    const { d } = useI18n();
    const vnodeProps = getCurrentInstance()?.vnode.props || {};

    watch(data.value, () => {
      if ("onCurrentChange" in vnodeProps) {
        currentSort.value = label.value + order.value;
      } else {
        pagination.value.total = data.value.length;
      }
    });

    onMounted(() => {
      pagination.value.total = props.total ? props.total : data.value.length;
      pagination.value.rowsPerPage = props.rowsPerPage;
    });

    const getItems: ComputedRef<Array<FbmOrder>> = computed(() => {
      if ("onCurrentChange" in vnodeProps) {
        return filterData.value;
      } else {
        const clone = JSON.parse(JSON.stringify(filterData.value));
        const startFrom =
          pagination.value.page * pagination.value.rowsPerPage -
          pagination.value.rowsPerPage;
        return clone.splice(startFrom, pagination.value.rowsPerPage);
      }
    });
    const search = ref("");
    const filterData = computed(() => {
      return data.value.filter((user) => {
        return (
          user.buyerEmail.toLowerCase().indexOf(search.value.toLowerCase()) !=
            -1 || user.amazonOrderId.indexOf(search.value) != -1
        );
      });
    });

    const currentPageChange = (val) => {
      if ("onCurrentChange" in vnodeProps) {
        emit("current-change", val);
      } else {
        pagination.value.page = val;
      }
    };

    const setItemsPerPage = (event) => {
      if ("onItemsPerPageChange" in vnodeProps) {
        emit("items-per-page-change", parseInt(event.target.value));
      } else {
        pagination.value.rowsPerPage = parseInt(event.target.value);
      }
    };

    return {
      search,
      filterData,
      pagination,
      currentPageChange,
      getItems,
      setItemsPerPage,
      d,
    };
  },
});
